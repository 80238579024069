import React from 'react';
// MUI Icon Imports
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
// Component imports
import ProductBox from "./ProductBox";

// Styling imports
import "./Services.css"

// Class for the services section of the website
const Services = () => {
    return (
        <React.Fragment>
            <section className='ServicesSection' id="services"> 
            
            <h1 className='title'>Services</h1>

            <div className='product-container'>  
            <ProductBox 
                    title="Consulting" 
                    icon={<ContactsOutlinedIcon sx={{ fontSize: 40 }}/>} 
                    description="Personalized advisory services leveraging AI technologies to optimize 
                    business strategies, processes, and decision-making, enhancing efficiency and competitiveness." 
            />
            
            <ProductBox 
                title="AI Agents" 
                icon={<SupportAgentOutlinedIcon sx={{ fontSize: 40 }}/>} 
                description="Diverse AI-driven virtual agents tailored to various tasks and industries, providing 
                automated assistance, support, and interaction with users to streamline operations and improve customer experiences." 
            />

            <ProductBox 
                title="Lead Generation" 
                icon={<Diversity3OutlinedIcon sx={{ fontSize: 40 }}/>} 
                description="AI-powered solutions that analyze data and customer behavior to identify and attract potential leads, 
                enabling businesses to expand their customer base and increase revenue through targeted marketing and sales efforts." 
            />
            </div>
            
            </section>
        </React.Fragment>
    );
};

export default Services;