import React from 'react';

// Styling import
import "./ProductBox.css"

// This is the component for the Boxes with each of the services
const ProductBox = ({ title, icon, description }) => { 
    return (
        <div className="product-box">
            <h2 className="product-title">{title}</h2>
            {icon && <div className="product-icon">{icon}</div>} 
            <p className="product-description">{description}</p>
        </div>
    );
};

export default ProductBox;