import React from 'react';

import AboutBox from './AboutBox';

import "./About.css"

// This is the page for the About Section
const About = () => {
    return (
        <section className="about-section" id="about">
         
            <h2>How it works</h2>

            <div className="about-content">
                <p>Say goodbye to the lengthy onboarding processes. Simply give us a call and we'll take care of the rest!</p>
            </div>   
            <div className="about_boxes">

        <AboutBox 
          number={1} 
          title="Discovery workshop" 
          description="We kick off with a Discovery call where our team analyzes your business and ascertains which of our solutions would provide maximal value." 
        />        
        <AboutBox 
          number={2} 
          title="Onboarding" 
          description="You will be matched with a developer who will spearhead your project and maintain constant communcation with you via a dedicated Slack channel." 
        />
        <AboutBox 
          number={3} 
          title="Build timeline" 
          description="Three days after the workshop, you will receive a build timeline and plan which includes a list of all the solutions our team will provide you." 
        />
        <AboutBox 
          number={4} 
          title="Build & integration" 
          description="With your approval, our team will build your new A.I. systems and seamlessly integrate them into your business operations, making sure that the solutions are beautiful and intuitive to use!" 
        />
      </div>
        </section>
    );
};
export default About;