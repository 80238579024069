import "./AboutBox.css"

import React from 'react';

// This is the class for the about description

const AboutBox = ({ number, title, description }) => {
    return (
        <div className="about-box">
            <div className="number">{number}</div>
            <h3 className="title_about">{title}</h3>
            <p className="description">{description}</p>
        </div>
    );
};

export default AboutBox;
