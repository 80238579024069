//Navbar.jsx

// Imports all the icons from Mui
// import { useNavigate } from 'react-router-dom';
//import React, { useState , useEffect } from "react";
//import { Link } from "react-router-dom";
// import { BsCart2 } from "react-icons/bs";
// import { HiOutlineBars3 } from "react-icons/hi2";
// import Box from "@mui/material/Box";
// import Drawer from "@mui/material/Drawer";
// import List from "@mui/material/List";
// import Divider from "@mui/material/Divider";
// import ListItem from "@mui/material/ListItem";
// import ListItemButton from "@mui/material/ListItemButton";
// import ListItemIcon from "@mui/material/ListItemIcon";
// import ListItemText from "@mui/material/ListItemText";
// import HomeIcon from "@mui/icons-material/Home";
// import InfoIcon from "@mui/icons-material/Info";
// import CommentRoundedIcon from "@mui/icons-material/CommentRounded";
// import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
// import logo from '../Images/LogoBlank.png';
//import banner from '../Images/Banner.png'


// Styling import
//import "./Navbar.css"

// This is the class for the navbar
// const Navbar = () => {
//     const [openMenu, setOpenMenu] = useState(false);

//     // const menuOptions = [
//     //   {
//     //     text: "Home",
//     //     icon: <HomeIcon />,
//     //   },
//     //   {
//     //     text: "About",
//     //     icon: <InfoIcon />,
//     //   },
     
//     //   {
//     //     text: "Contact",
//     //     icon: <PhoneRoundedIcon />,
//     //   },

//     // ];
//     return (
//       <nav>
//         <div className="nav-logo-container">

//         </div>
//         <div className="navbar-links-container">
//           <Link to="/">Home</Link>
//           <Link to="/services">Services</Link>
//           <Link to="/about">About</Link>
//           <button className="primary-button">Contact</button>
//         </div>
       
       
       
//         {/* <div className="navbar-menu-container">
//           <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
//         </div>
//         <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="right">
//           <Box
//             sx={{ width: 250 }}
//             role="presentation"
//             onClick={() => setOpenMenu(false)}
//             onKeyDown={() => setOpenMenu(false)}
//           >
//             <List>
//               {menuOptions.map((item) => (
//                 <ListItem key={item.text} disablePadding>
//                   <ListItemButton>
//                     <ListItemIcon>{item.icon}</ListItemIcon>
//                     <ListItemText primary={item.text} />
//                   </ListItemButton>
//                 </ListItem>
//               ))}
//             </List>
//             <Divider />
//           </Box>
//         </Drawer> */}

//       </nav>
//     );
//   };
  
//   export default Navbar;

// const Navbar = () => {
//   const [scrolled, setScrolled] = useState(false);

//   useEffect(() => {
//     const handleScroll = () => {
//       const isScrolled = window.scrollY > 0;
//       if (isScrolled !== scrolled) {
//         setScrolled(isScrolled);
//       }
//     };

//     window.addEventListener('scroll', handleScroll);
//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, [scrolled]);

//   return (
//   <nav className={`nav ${scrolled ? 'navbar scrolled' : 'navbar'}`}>
//       <div className="nav-logo-container"></div>
//       <div className="nav-left">
//         <img src={banner}  alt="Logo" />
//       </div>
//       <div className="nav-right">
//         <div className="navbar-links-container">
//           <Link to="/">Home</Link>
//           <Link to="/services">Services</Link>
//           <Link to="/about">About</Link>
//           <button className="primary-button">Contact</button>
//         </div>
//        </div>
      
//     </nav>
//   );
// };

// export default Navbar;


// const Navbar = () => {
//   const [scrolled, setScrolled] = useState(false);

//   useEffect(() => {
//     const handleScroll = () => {
//       const isScrolled = window.scrollY > 0;
//       if (isScrolled !== scrolled) {
//         setScrolled(isScrolled);
//       }
//     };

//     window.addEventListener('scroll', handleScroll);
//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, [scrolled]);

//   // const scrollToSection = (id) => {
//   //   const section = document.getElementById(id);
//   //   if (section) {
//   //     section.scrollIntoView({ behavior: "smooth" });
//   //   }
//   // };
//   const scrollToSection = (id) => {
//     const section = document.getElementById(id);
//     const navbarHeight = document.querySelector('.nav').offsetHeight;
//     if (section) {
//       const offset = section.offsetTop - navbarHeight;
//       window.scrollTo({
//         top: offset,
//         behavior: "smooth"
//       });
//     }
//   };
  
//   return (
//     <nav className={`nav ${scrolled ? 'navbar scrolled' : 'navbar'}`}>
//       <div className="nav-logo-container"></div>
//       <div className="nav-left">
//         <img src={banner} alt="Logo" />
//       </div>
//       <div className="nav-right">
//         <div className="navbar-links-container">
//           <Link to="/" onClick={() => scrollToSection('home')} className="navbar-link">Home</Link>
//           <Link to="/" onClick={() => scrollToSection('services')} className="navbar-link">Services</Link>
//           <Link to="/" onClick={() => scrollToSection('about')} className="navbar-link">About</Link>
//           <a href="#contact" className="navbar-link">Contact</a>
//         </div>
//       </div>
//     </nav>
//   );
// };


// export default Navbar;

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import banner from '../Images/Banner.png';
import "./Navbar.css";

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);

  // Define scrollToSection function to handle navigation and smooth scroll
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      const navbarHeight = document.querySelector('.nav').offsetHeight;
      const rect = section.getBoundingClientRect();
      const offset = rect.top + window.scrollY - navbarHeight;
      window.scrollTo({
        top: offset,
        behavior: "smooth"
      });
    }
  };

  const handleContactClick = () => {
    const recipient = 'services@neuralog.ai';
    const subject = encodeURIComponent('Service inquiry ');
    const body = encodeURIComponent('Enter Message and Contact Info:');

    window.location.href = `mailto:${recipient}?subject=${subject}&body=${body}`;
  };

  
  

  useEffect(() => {
    // Handle scroll for navbar effect
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };
    window.addEventListener('scroll', handleScroll);

    // Load the chatbot script dynamically
    const script = document.createElement('script');
    script.src = "https://cdn.botpress.cloud/webchat/v1/inject.js";
    script.async = true;
    document.body.appendChild(script);

    // Load the chatbot config script
    const configScript = document.createElement('script');
    configScript.src = "https://mediafiles.botpress.cloud/25b09bb6-0a65-480b-8376-8ebbebe78a59/webchat/config.js";
    configScript.defer = true;
    document.body.appendChild(configScript);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.body.removeChild(script);
      document.body.removeChild(configScript);
    };
  }, [scrolled]);

  return (
    <nav className={`nav ${scrolled ? 'navbar scrolled' : 'navbar'}`}>
      <div className="nav-logo-container"></div>
      <div className="nav-left">
        <img src={banner} alt="Logo" />
      </div>
      <div className="nav-right">
        <div className="navbar-links-container">
          <Link to="/" onClick={() => scrollToSection('home')} className="navbar-link">Home</Link>
          <Link to="/" onClick={() => scrollToSection('services')} className="navbar-link">Services</Link>
          <Link to="/" onClick={() => scrollToSection('about')} className="navbar-link">About</Link>
          <button onClick={handleContactClick} className="primary-button">Contact</button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
