import React from 'react';

// Component imports
import "./Hero.css";
// import Logo from "../../Images/AILogo.jpg"

// This is the class for the hero header
const HeroHeader = () => {
    return (
        <section className="section" id="home" >
            {/* Left half */}
            <div className="left-half">
                <div className="slogan-container">
                    <h2 className="slogan">Ignite your workspace  </h2>
                    <p className="slogan-description">Spark Transformation with custom solutions built just for you</p>
                </div>
            </div>
            

        </section>
    );
};


export default HeroHeader;
