// import logo from './logo.svg';
// CSS Import
import './App.css';
// Routing Impprt
import { BrowserRouter as Router, Route,Routes} from "react-router-dom";
import ScrollToTop from './Components/ScrollToTop'; 

//Import Components
import Navbar from './Components/Navbar';
// import Navbar2 from './Components/Navbar2';
import Home from './Components/Home/Home'
// import Services from './Components/Services/Services'
// import About from './Components/About/About'
import Footer from './Components/Footer'

// Routing and app initialization
const App = () => {
  return (
    <Router>
      <ScrollToTop/>
      <Routes>

        {/* Blank route */}
        <Route path="/" element={<>
          <Navbar />
          <Home />
        </>} />

      </Routes>

      <Footer />
    </Router>
  );
};

export default App;
