import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa'
import { Link } from 'react-router-dom'

// styling import
import "./Footer.css"



// This is the footer for the website
const Footer = () => {
    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        const navbarHeight = document.querySelector('.nav').offsetHeight;
        if (section) {
          const offset = section.offsetTop - navbarHeight;
          window.scrollTo({
            top: offset,
            behavior: "smooth"
          });
        }
      };
    return (
        <section>
        <div className='footer'>
            <div className='container'>

                {/* The footer section with the pages */}
                <div className='col solutions'>
                    <h1>Pages</h1>
                    <ul>
                        {/* <li><Link to="/" className='link'>Home</Link></li>
                        <li><Link to="/services" className='link'>Services</Link></li>
                        <li><Link to="/about" className='link'>About</Link></li> */}
                        <Link to="/" onClick={() => scrollToSection('home')} className="link">Home</Link>
                        <Link to="/" onClick={() => scrollToSection('services')} className="link">Services</Link>
                        <Link to="/" onClick={() => scrollToSection('about')} className="link">About</Link>
                    </ul>
                </div>



                {/* The footer section with the social media links */}
                <div className='col legal'>
                    <h1>Social Coming Soon:</h1>
                    <ul class="flex">
                        <FaFacebook size={20} style={{color: '#d3d3d3', marginRight: '10px', marginTop:'15px'}} />
                        <FaInstagram size={20} style={{color: '#d3d3d3', marginRight: '10px',marginTop:'15px'}} />
                        <FaTwitter size={20} style={{color: '#d3d3d3', marginRight: '10px',marginTop:'15px'}} />
                        <FaLinkedin size={20} style={{color: '#d3d3d3', marginRight: '10px',marginTop:'15px'}} />
                    </ul>
                </div>
  
            </div>

            {/* Bottom of the footer with  */}
            <div className='footer-bottom'>
                <div className='content'>
                    <div className='rights'>
                        <p >&copy; Neuralog LLC. All rights reserved.</p>
                    
                </div>
            </div>
        </div>
        </div>
        </section>
    );
};

export default Footer
