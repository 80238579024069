import React from 'react';

// Component Imports
import HeroHeader from "./HeroHeader";
import Services from "../Services/Services"
import About from "../About/About"

// This is the class for the Home section
const Home = () => {
    return (
        <React.Fragment>
            <HeroHeader />
            <Services />
            <About />
        </React.Fragment>
    );
};

export default Home;